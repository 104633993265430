import React from "react";
import { Checkbox } from "react-bootstrap";

const AcknowledgeChange = ({
	isCheckoutChangeAcknowledged,
	onCheckoutChangeAcknowledgedChange,
	isMakingPayment
}) => {
	return (
		<Checkbox
			disabled={isMakingPayment}
			checked={isCheckoutChangeAcknowledged}
			onChange={onCheckoutChangeAcknowledgedChange}
		>
			{" "}
			I have reviewed the items I am checking out.
		</Checkbox>
	);
};

export default AcknowledgeChange;
