import { checkStatus, parseJSON } from "fetch-helpers";

export function fetchCheckout(auth) {
	return fetch(`${process.env.ADMIN_API_URL}checkout/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function makeCheckoutPayment({ items, bankAccount, auth }) {
	return fetch(`${process.env.ADMIN_API_URL}checkout/`, {
		method: "POST",
		headers: populateHeaders(auth),
		body: JSON.stringify({
			items,
			bankAccount
		})
	})
		.then(checkStatus)
		.then(parseJSON);
}

function populateHeaders(auth) {
	let headers = {
		Accept: "application/json",
		"Content-type": "application/json"
	};

	if (auth) {
		headers.Authorization = `Bearer ${auth.token}`;
	}

	return headers;
}
