import React from "react";
import {
	BankAccountPicker as _BankAccountPicker,
	connectUserBankAccounts
} from "@civicsource/users";
import { FormGroup } from "react-bootstrap";

const BankAccountPicker = connectUserBankAccounts(_BankAccountPicker);

const SelectPayment = ({
	username,
	isMakingPayment,
	selectedBankAccount,
	id = "checkout-select-payment",
	onBankAccountChange
}) => {
	return (
		<FormGroup>
			<BankAccountPicker
				value={selectedBankAccount}
				disabled={isMakingPayment}
				{...{ id, username, onBankAccountChange }}
			/>
		</FormGroup>
	);
};

export default SelectPayment;
