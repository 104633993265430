import { useFetch } from "react-fetch-hooks";

const useTaxAuthority = taPrefix => {
	let { body: taxAuthority, isFetching, isFetched, error } = useFetch(
		`${process.env.ADMIN_API_URL}/${taPrefix}`
	);

	return {
		isLoading: isFetching,
		isLoaded: isFetched,
		isError: !!error,
		taxAuthority
	};
};

export default useTaxAuthority;
