import React from "react";
import connect from "./connect";

import { Card, CardContent, withStyles } from "@material-ui/core";
import { Notifier } from "@civicsource/ui";
import enforceLogin from "../login";
import CheckoutFlow from "./flow";

const Root = props => (
	<div className="container">
		<Card elevation={1} className={props.classes.root}>
			<CardContent>
				<CheckoutFlow {...props} />
			</CardContent>
		</Card>
		<Notifier timeout={5000} />
	</div>
);

const styles = theme => {
	return {
		"@global html": {
			fontSize: 16
		},
		root: {
			...theme.typography.body1
		}
	};
};

export default enforceLogin(connect(withStyles(styles)(Root)));
