import React from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { Row, Col, Table } from "react-bootstrap";

import CheckoutItem from "./checkout-item";
import TableFooter from "./table-footer";
import { accountStatementVerbiage } from "./make-payment-button";
import CheckoutActions from "./actions";
import CheckoutProgress from "./progress";

import scrollTop from "./scroll-top";

const NEW_ITEM_FADE_DURATION = 5000;

const timeout = { enter: NEW_ITEM_FADE_DURATION };

const rowTransitions = {
	transitionProps: {
		transition: `${NEW_ITEM_FADE_DURATION}ms background-color`
	},
	enter: {
		extend: "transitionProps",
		backgroundColor: "#f0de4e"
	},
	enterActive: {
		extend: "transitionProps",
		backgroundColor: "rgba(240, 222, 78, 0)"
	}
};

const styles = {
	container: {
		fontFamily: ["Open Sans", "sans-serif"],
		color: bootstrap.textColor
	},
	table: {
		borderWidth: 0,
		"& tbody tr": {
			"td:first-child": {
				borderLeft: `1px solid ${bootstrap.tableBgHover}`
			}
		}
	},
	amount: {
		textAlign: "right"
	},
	noPadding: {
		padding: "0 !important"
	},
	indent: {
		paddingLeft: "30px !important"
	},
	totalRow: {
		fontSize: bootstrap.fontSizeH4,
		borderTop: `2px solid #ddd`,
		"& th, & td": {
			fontWeight: "normal"
		}
	},
	address: {
		composes: "text-uppercase",
		marginLeft: "2em",
		"&, &:hover, &:focus": {
			color: bootstrap.textColor,
			textDecoration: "none"
		},
		borderBottom: "1px solid transparent",
		"&:hover, &:focus": {
			borderBottomColor: "#919191"
		}
	},
	progressWrapper: {
		textAlign: "center"
	},
	...rowTransitions
};

class CheckoutRoot extends React.Component {
	componentDidUpdate() {
		const { checkout, fetchCheckout, username } = this.props;

		if (!checkout.isLoading && checkout.isLoaded && checkout.isProcessing) {
			this.reloadDeferralsTimeout = setTimeout(
				() => fetchCheckout(username),
				5000
			);
		}
	}

	componentWillUnmount() {
		if (this.reloadDeferralsTimeout) clearTimeout(this.reloadDeferralsTimeout);
	}

	render() {
		const { classes, checkout, supportPhone, ...props } = this.props;

		if (checkout.isLoadingError) {
			if (checkout.isTooManyItems) {
				return (
					<div className={classes.progressWrapper}>
						<Typography variant="subheading" gutterBottom>
							Too many items to checkout
						</Typography>
						<Typography variant="body1">
							Please contact support in order to process your order:
							<br />
							{supportPhone}
						</Typography>
					</div>
				);
			}
			return (
				<div className={classes.progressWrapper}>
					<Typography variant="subheading" gutterBottom>
						Problem loading checkout.
					</Typography>
				</div>
			);
		}

		if (checkout.isLoading && !checkout.isLoaded) {
			return (
				<div className={classes.progressWrapper}>
					<Typography variant="subheading" gutterBottom>
						Loading checkout&hellip;
					</Typography>
					<CheckoutProgress isQuerying />
				</div>
			);
		}

		if (checkout.isProcessing) {
			return (
				<div className={classes.progressWrapper}>
					<Typography variant="subheading" gutterBottom>
						{checkout.completed} of {checkout.count} items processed
					</Typography>
					<CheckoutProgress
						isQuerying={checkout.isLoading}
						progress={{
							completed: checkout.completed,
							total: checkout.count
						}}
					/>
				</div>
			);
		}

		if (!checkout.items.length) {
			return (
				<div className={classes.progressWrapper}>
					<Typography variant="subheading" gutterBottom>
						No items to checkout
					</Typography>
					<CheckoutProgress />
				</div>
			);
		}

		const header = checkout.isPaymentMade ? (
			<div>
				<h1>Thank you for your purchase.</h1>
				{accountStatementVerbiage}
			</div>
		) : (
			<h1>Checkout</h1>
		);

		return (
			<div className={classes.container}>
				<style>
					{`
				@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100 %, 0, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

				.fadeInRight {
	animation - name: fadeInRight;
}
`}
				</style>
				<Row>
					<Col md={12}>
						{header}
						<TransitionGroup component={Table} className={classes.table}>
							<thead>
								<tr>
									<th colSpan={2}>Item</th>
									<th className={classes.amount}>Price</th>
									{checkout.isPaymentMade ? null : (
										<th className={classes.amount}>Amount Due</th>
									)}
								</tr>
							</thead>

							<TableFooter classes={classes} checkout={checkout} />

							{checkout.items.map(({ id, taxAuthorityPrefix, ...item }) => (
								<CSSTransition
									classNames={classes}
									transitionExit={false}
									timeout={timeout}
									key={id}
								>
									<CheckoutItem
										isPaymentMade={checkout.isPaymentMade}
										taPrefix={taxAuthorityPrefix}
										{...item}
										{...{ classes }}
									/>
								</CSSTransition>
							))}
						</TransitionGroup>
						{checkout.items.some(i => i.isPastPaymentWindow) ? (
							<div>
								<span>
									<Error
										style={{
											color: "#FF5722",
											verticalAlign: "middle",
											marginBottom: 2
										}}
									/>
								</span>{" "}
								Some items in your cart are past the payment due date. Please{" "}
								<a href="/help">contact support</a> to submit a payment.
							</div>
						) : null}
						<CheckoutActions
							checkout={checkout}
							noPayableCheckoutItems={checkout.items.every(
								i => i.isPastPaymentWindow
							)}
							{...props}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default useSheet(styles)(scrollTop(CheckoutRoot));
