import { createReducer, mergeWithCurrent, registerReducer } from "redux-rsi";
import { createSelector } from "reselect";
import Immutable from "seamless-immutable";
import { sumBy } from "lodash";
import moment from "moment";

const TREE_ROOT = "checkout";

const reducer = createReducer(Immutable({}), {
	onCheckoutForUserFetch(state, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoading: true
			},
			init
		);
	},

	onCheckoutForUserFetchCompleted(
		state,
		{ items, w9TaxAuthorities, deferralProgress },
		{ username }
	) {
		const mappedItems = items.map(i => {
			const endDate = moment(i.paymentWindowEnd).valueOf();
			const isPastPaymentWindow = moment(new Date()) > endDate;
			return { ...i, isPastPaymentWindow };
		});

		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoading: false,
				isLoaded: true,
				isLoadingError: false,
				items: mappedItems,
				w9TaxAuthorities,
				count: deferralProgress.total,
				completed: deferralProgress.completed,
				isProcessing: deferralProgress.total > deferralProgress.completed
			},
			init
		);
	},

	onCheckoutForUserFetchFailed(state, err, { username }) {
		const isTooManyItems = err && err.response && err.response.status == 501;
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoading: false,
				isLoadingError: true,
				isTooManyItems
			},
			init
		);
	},

	onCheckoutCountForUserFetch(state, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoadingCount: true
			},
			init
		);
	},

	onCheckoutCountForUserFetchCompleted(state, count, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoadingCount: false,
				isLoadingCountError: false,
				count
			},
			init
		);
	},

	onCheckoutCountForUserFetchFailed(state, err, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isLoadingCount: false,
				isLoadingCountError: true
			},
			init
		);
	},

	onCheckoutMakePayment(state, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isMakingPayment: true
			},
			init
		);
	},

	onCheckoutMakePaymentCompleted(
		state,
		{ items, needsW9Agreement },
		{ username }
	) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isMakingPayment: false,
				isPaymentMade: true,
				isMakingPaymentError: false,
				isChanged: false,
				items,
				needsW9Agreement,
				count: 0
			},
			init
		);
	},

	onCheckoutMakePaymentFailed(state, err, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isMakingPayment: false,
				isMakingPaymentError: true
			},
			init
		);
	},

	onCheckoutMakePaymentItemsChanged(state, err, { username }) {
		return mergeWithCurrent(
			state,
			normalize(username),
			{
				isMakingPayment: false,
				isMakingPaymentError: true,
				isChanged: true
			},
			init
		);
	}
});

function normalize(username) {
	return username ? username.toLowerCase() : username;
}

function init() {
	return Immutable({
		isLoading: false,
		isLoaded: false,
		isLoadingError: false,
		isMakingPayment: false,
		isPaymentMade: false,
		isMakingPaymentError: false,
		isChanged: false,
		items: [],
		w9TaxAuthorities: [],
		isLoadingCount: false,
		isLoadingCountError: false,
		count: 0,
		completed: 0,
		isProcessing: false
	});
}

function _getCheckoutByUsername(state, username) {
	return state[TREE_ROOT][normalize(username)] || init();
}

export const getCheckoutByUsername = createSelector(
	_getCheckoutByUsername,
	results =>
		results.set(
			"total",
			sumBy(results.items.filter(i => !i.isPastPaymentWindow), "amountUnpaid")
		)
);

registerReducer(TREE_ROOT, reducer);
