import React from "react";
import { Button, Collapse } from "react-bootstrap";
import { Spinner } from "@civicsource/ui";
import useSheet from "react-jss";
import { f$ } from "./line-item";

export const accountStatementVerbiage = (
	<p>You'll see one payment per auction in your bank account.</p>
);

const styles = {
	btnTransitions: {
		transition: "500ms all",
		overflow: "hidden"
	},
	btnText: {
		composes: "$btnTransitions",
		maxWidth: "40em",
		opacity: 1
	},
	btnTextActive: {
		composes: "$btnTransitions",
		maxWidth: 0,
		opacity: 0
	}
};

const MakePaymentButton = ({
	isAgreedToTerms,
	isCheckoutChangeAcknowledged,
	isW9ChecklistValid,
	isChanged,
	selectedBankAccount,
	selectedBankAccountName,
	total,
	onMakePayment,
	isBusy,
	classes,
	noPayableCheckoutItems
}) => {
	const accountText = selectedBankAccount
		? `with ${selectedBankAccountName}`
		: null;
	const buttonContents = (
		<div>
			<Collapse in={!isBusy} unmountOnExit>
				<div>
					<div className={isBusy ? classes.btnTextActive : classes.btnText}>
						Submit Payment for {f$(total)} {accountText}
					</div>
				</div>
			</Collapse>
			<Collapse in={isBusy} unmountOnExit>
				<div>
					<Spinner /> Submitting Payment
				</div>
			</Collapse>
		</div>
	);

	return (
		<div className="clearfix">
			<div className="pull-right">
				<Button
					bsSize="large"
					bsStyle="primary"
					disabled={
						!isW9ChecklistValid ||
						!isAgreedToTerms ||
						!selectedBankAccount ||
						isBusy ||
						(isChanged && !isCheckoutChangeAcknowledged) ||
						noPayableCheckoutItems
					}
					active={isBusy}
					onClick={onMakePayment}
				>
					{buttonContents}
				</Button>
			</div>
		</div>
	);
};

export default useSheet(styles)(MakePaymentButton);
