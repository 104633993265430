import React from "react";
import getTaxAuthority from "./get-tax-authority";
import AuctionIcon, { SALE_TYPES } from "../../info-block/icon";
import { invert, get, startCase } from "lodash";
import CartLineItem, { LINE_ITEM_TYPES, f$ } from "./line-item";
import EFTLimit, { showWarning } from "./eft-limit";
import AuctionLink from "../../auction/link";
import { useFetch } from "react-fetch-hooks";
import { getAuctionTypes } from "../../auction/details/connect";
import { Error } from "@material-ui/icons";
import moment from "moment";

export const CHECKOUT_TYPE_CODES = {
	PURCHASE: "PUR",
	DEPOSIT: "DEP",
	ACH_PAYMENT: "ACH",
	ACH_REFUND: "ARF",
	CASH_PAYMENT: "CSH",
	CERTIFIED_FUNDS_PAYMENT: "CER",
	CHECK_REFUND: "RCK",
	PAYMENT_HOLD: "HDP",
	REFUND_HOLD: "HDR",
	WIRE_TRANSFER_PAYMENT: "WTR"
};

export const CHECKOUT_TYPE_CODE_LOOKUP = invert(CHECKOUT_TYPE_CODES);

export const typeCodeToDescription = code => {
	if (code === CHECKOUT_TYPE_CODES.PURCHASE) return "Auction";
	const result = get(CHECKOUT_TYPE_CODE_LOOKUP, code);

	return result ? startCase(result.toLowerCase()) : result;
};

const CheckoutItem = ({
	lotNumber,
	auctionPrice,
	amount,
	typeCode,
	createdOn,
	amountUnpaid,
	buyerCosts,
	payments,
	classes,
	isPaymentMade,
	taPrefix,
	isPastPaymentWindow,
	paymentWindowEnd
}) => {
	const { body: auction, isFetched: isLoaded } = useFetch(
		lotNumber
			? `/api/auctions/?lotNumber=${lotNumber}&taxAuthority=${taPrefix}`
			: null
	);
	const { taxAuthority } = getTaxAuthority(taPrefix);

	const eftWarning =
		taxAuthority && showWarning({ amount: amountUnpaid, taxAuthority }) ? (
			<EFTLimit {...{ amount: amountUnpaid, taxAuthority }} />
		) : null;
	const displayedAmount =
		typeCode === CHECKOUT_TYPE_CODES.PURCHASE ? auctionPrice : amount;

	const { isAdjudicationSale, isTaxSale } = auction
		? getAuctionTypes(auction.auctionType)
		: {};

	const auctionType = isAdjudicationSale
		? SALE_TYPES.ADJ
		: isTaxSale
		? SALE_TYPES.TAX
		: SALE_TYPES.LIEN;

	return (
		<tbody style={{ opacity: isPastPaymentWindow ? 0.7 : 1 }}>
			<tr>
				<td colSpan={80}>
					{auction && isLoaded ? (
						<AuctionIcon
							auctionType={auctionType}
							size="2em"
							style={{
								display: "inline-block",
								position: "absolute",
								marginTop: 2
							}}
						/>
					) : null}
					<AuctionLink lotNumber={lotNumber} className={classes.address}>
						<strong>
							{auction && isLoaded
								? get(auction, "address.address1") || "Unspecified"
								: ""}
						</strong>
					</AuctionLink>{" "}
					{lotNumber}
				</td>
			</tr>
			<CartLineItem
				amount={displayedAmount}
				{...{ createdOn, isPaymentMade }}
				isPositive
				name={typeCodeToDescription(typeCode)}
				warning={
					isPastPaymentWindow ? (
						<span
							title={`Payment was due on ${moment(paymentWindowEnd).format(
								"L LT"
							)}`}
						>
							<Error
								style={{
									color: "#F44336",
									verticalAlign: "middle",
									marginBottom: 1,
									fontSize: 18
								}}
							/>
						</span>
					) : null
				}
			/>
			{buyerCosts.map(({ id, name, revenue, createdOn }) => (
				<CartLineItem
					key={id}
					{...{ name, revenue, createdOn, isPaymentMade }}
					lineItemType={LINE_ITEM_TYPES.BUYER_COST}
				/>
			))}
			{payments.map(({ paymentId, amount, paymentCreatedOn, paymentType }) => (
				<CartLineItem
					key={paymentId}
					{...{
						amount,
						createdOn: paymentCreatedOn,
						isPaymentMade,
						type: paymentType
					}}
					lineItemType={LINE_ITEM_TYPES.PAYMENT}
				/>
			))}
			{!isPaymentMade ? (
				<tr className="active">
					<td className={classes.indent} colSpan={3}>
						<small>Subtotal</small>
					</td>
					<td className={classes.amount}>
						<strong>{f$(amountUnpaid)}</strong>
					</td>
				</tr>
			) : null}
			{eftWarning ? (
				<tr>
					<td colSpan={100} className={classes.noPadding}>
						{eftWarning}
					</td>
				</tr>
			) : null}
		</tbody>
	);
};

export default CheckoutItem;
