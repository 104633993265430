import React from "react";
import { Grid } from "react-bootstrap";
import Checkout from "../checkout";
import { graphql } from "gatsby";
import { get } from "lodash";

const CheckoutPage = ({ data }) => {
	const supportPhone = get(data, "contentfulContactInfo.value");
	return (
		<Grid style={{ paddingTop: 16, paddingBottom: 16 }}>
			<Checkout supportPhone={supportPhone} />
		</Grid>
	);
};

export const pageQuery = graphql`
	query CheckoutQuery {
		contentfulContactInfo(type: { eq: "Phone" }) {
			value
		}
	}
`;

export default CheckoutPage;
