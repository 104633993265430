import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOnUpdate } from "fetch-helpers";
import { fetchCheckout, makeCheckoutPayment } from "./actions";
import { getCheckoutByUsername } from "./reducers";
import { getCurrentUser } from "@civicsource/users";

export default function connectCheckout(Component) {
	const Fetcher = fetchOnUpdate(({ fetchCheckout, username }) => {
		if (username) {
			fetchCheckout(username);
		}
	}, "username")(Component);

	const Container = connect(
		state => {
			const user = getCurrentUser(state);
			if (user) {
				return {
					username: user.username,
					checkout: getCheckoutByUsername(state, user.username)
				};
			}
		},
		dispatch =>
			bindActionCreators({ fetchCheckout, makeCheckoutPayment }, dispatch)
	)(Fetcher);

	return Container;
}
