import React from "react";
import { f$ } from "./line-item";

const TableFooter = ({ classes, checkout: { total } }) => {
	const formattedTotal = total === null ? null : f$(total);

	if (!total) {
		return null;
	}

	return (
		<tfoot>
			<tr className={classes.totalRow}>
				<td colSpan={3}>
					<strong>Total Due</strong>
				</td>
				<td className={classes.amount}>
					<strong>{formattedTotal}</strong>
				</td>
			</tr>
		</tfoot>
	);
};

export default TableFooter;
