import React from "react";

import { Collapse, FormGroup, Row, Col } from "react-bootstrap";

import TermsAgreer from "./terms-agreer";
import TaxAuthorityAgreer from "./tax-authority-agreer";
import AcknowledgeChange from "./acknowledge-change";
import SelectPayment from "./select-payment";
import MakePaymentButton, {
	accountStatementVerbiage
} from "./make-payment-button";
import { W9_INPUT_PREFIX } from ".";

const CheckoutActions = ({
	username,
	checkout,
	onBankAccountChange,
	onAgreeToTermsChange,
	onAgreeW9Change,
	onCheckoutChangeAcknowledgedChange,
	onMakePayment,
	selectedBankAccount,
	selectedBankAccountName,
	isCheckoutChangeAcknowledged = false,
	isAgreedToTerms = false,
	noPayableCheckoutItems,
	...props
}) => {
	const { isPaymentMade, isMakingPayment } = checkout;

	const w9Checklist = checkout.w9TaxAuthorities.length ? (
		<FormGroup>
			{checkout.w9TaxAuthorities.map(ta => {
				return (
					<TaxAuthorityAgreer
						{...props}
						{...ta}
						key={ta.prefix}
						{...{ onAgreeW9Change, isMakingPayment, isPaymentMade }}
					/>
				);
			})}
		</FormGroup>
	) : null;

	if (isPaymentMade) {
		if (!checkout.w9TaxAuthorities.length) {
			return null;
		}

		return w9Checklist;
	}

	const w9Keys = Object.keys(props).filter(k => k.includes(W9_INPUT_PREFIX));
	const isW9ChecklistValid = w9Keys.length ? w9Keys.every(k => props[k]) : true;

	return (
		<div>
			<FormGroup>
				<TermsAgreer
					{...{ isMakingPayment, isAgreedToTerms, onAgreeToTermsChange }}
				/>
			</FormGroup>
			{w9Checklist ? <hr /> : null}
			{w9Checklist}
			<Collapse in={checkout.isChanged} unmountOnExit>
				<div>
					<FormGroup>
						<AcknowledgeChange
							{...{
								isMakingPayment,
								isCheckoutChangeAcknowledged,
								onCheckoutChangeAcknowledgedChange
							}}
						/>
					</FormGroup>
				</div>
			</Collapse>
			{w9Checklist ? <hr /> : null}
			<Row>
				<Col md={6}>
					<SelectPayment
						{...{
							isMakingPayment,
							isAgreedToTerms,
							onBankAccountChange,
							selectedBankAccount,
							username
						}}
					/>
					{selectedBankAccount ? <div>{accountStatementVerbiage}</div> : null}
				</Col>
				<Col md={6}>
					<FormGroup>
						<MakePaymentButton
							{...{
								total: checkout.total,
								isBusy: isMakingPayment,
								selectedBankAccount,
								selectedBankAccountName,
								isAgreedToTerms,
								isCheckoutChangeAcknowledged,
								isW9ChecklistValid,
								isChanged: checkout.isChanged,
								onMakePayment,
								noPayableCheckoutItems
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
		</div>
	);
};

export default CheckoutActions;
