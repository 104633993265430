import React from "react";
import useSheet from "react-jss";
import { formatMoney } from "accounting";
import { bootstrap } from "toetag";

export const showWarning = ({
	amount,
	taxAuthority: { singleTransactionLimit, isLoading }
}) => (isLoading ? false : amount >= singleTransactionLimit);

const styles = {
	warningMessage: {
		composes: "container-fluid",
		background: bootstrap.brandDanger,
		color: "#fff",
		paddingTop: bootstrap.paddingBaseVertical,
		paddingBottom: bootstrap.paddingBaseVertical,
		fontSize: bootstrap.fontSizeSmall
	},
	link: {
		fontWeight: "bold",
		"&, &:hover, &:focus": {
			color: "#fff"
		}
	}
};

const EFTLimit = ({
	taxAuthority: { isLoading, singleTransactionLimit, name },
	amount,
	classes
}) => {
	if (isLoading) {
		return null;
	}

	if (
		showWarning({ amount, taxAuthority: { isLoading, singleTransactionLimit } })
	) {
		return (
			<div className={classes.warningMessage}>
				Subtotal exceeds {name}
				's electronic funds transfer limit of{" "}
				{formatMoney(singleTransactionLimit)} by{" "}
				{formatMoney(amount - singleTransactionLimit)}. Please{" "}
				<a className={classes.link} href="/help">
					contact us
				</a>{" "}
				for further assistance.
			</div>
		);
	}

	return null;
};

export default useSheet(styles)(EFTLimit);
