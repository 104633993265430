import React from "react";
import { formatMoney } from "accounting";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { TimeDisplay } from "@civicsource/ui";

export const FMT_OPTS = {
	format: {
		pos: "%s%v",
		neg: "(%s%v)",
		zero: "%s%v"
	}
};

const styles = {
	smallRow: {
		"& td:first-child": {
			paddingLeft: 30
		}
	},
	small: {
		fontSize: bootstrap.fontSizeSmall,
		padding: bootstrap.tableCondensedCellPadding
	},
	amount: {
		composes: "$small",
		textAlign: "right"
	}
};

export const f$ = amount => formatMoney(amount, FMT_OPTS);

export const LINE_ITEM_TYPES = {
	BUYER_COST: "Buyer Cost",
	PAYMENT: "Payment"
};

export const POSITIVE_LINE_ITEMS = [LINE_ITEM_TYPES.BUYER_COST];
export const NEGATIVE_LINE_ITEMS = [LINE_ITEM_TYPES.PAYMENT];

const CartLineItem = ({
	type,
	name = type,
	classes,
	createdOn,
	amount,
	revenue,
	isPaymentMade,
	lineItemType,
	isPositive = POSITIVE_LINE_ITEMS.includes(lineItemType),
	warning = null
}) => {
	const amt = amount || revenue;

	return (
		<tr className={classes.smallRow}>
			<td className={classes.small}>
				{createdOn ? (
					<TimeDisplay time={createdOn} short showTime={false} showYear />
				) : null}{" "}
				{warning}
			</td>
			<td className={classes.small}>{name}</td>
			<td className={classes.amount}>{f$(isPositive ? amt : -amt)}</td>
			{isPaymentMade ? null : <td />}
		</tr>
	);
};

export default useSheet(styles)(CartLineItem);
