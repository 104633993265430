import React from "react";
import { Checkbox } from "react-bootstrap";
import { W9_INPUT_PREFIX } from ".";
import ProcessingInstructions from "./processing-instructions";

const TaxAuthorityAgreer = ({
	prefix,
	name,
	onAgreeW9Change,
	isMakingPayment,
	w9Policy,
	isPaymentMade,
	containerComponent: Container = isPaymentMade ? "div" : Checkbox,
	...props
}) => {
	const inputName = `${W9_INPUT_PREFIX}${prefix}`;
	const isChecked = props[inputName];
	const preamble = isPaymentMade ? (
		"The"
	) : (
		<span>
			<strong>I understand</strong> that the
		</span>
	);

	const checkboxProps = isPaymentMade
		? {}
		: {
				disabled: isMakingPayment,
				name: inputName,
				checked: isChecked,
				onChange: onAgreeW9Change
		  };

	return (
		<div>
			<Container {...checkboxProps}>
				{preamble} above tax lien auction(s) from the seller ({name}) are
				contingent upon a{" "}
				<a href={w9Policy.w9FormUrl} rel="noopener noreferrer" target="_blank">
					W9 form
				</a>{" "}
				being completed and submitted to:
			</Container>
			<br />
			<ProcessingInstructions {...w9Policy} />
		</div>
	);
};

export default TaxAuthorityAgreer;
