import React, { Component } from "react";

export default function scrollCheckoutToTopAfterPayment(WrappedComponent) {
	return class DeferredRenderWrapper extends Component {
		componentDidUpdate(prevProps) {
			if (!prevProps.checkout) return;
			if (!this.props.checkout) return;

			if (
				!prevProps.checkout.isPaymentMade &&
				this.props.checkout.isPaymentMade
			) {
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
}
