import { createAjaxAction, asyncifyAction } from "redux-rsi";
import { getCurrentUser } from "@civicsource/users";
import { getCheckoutByUsername } from "./reducers";
import {
	fetchCheckout as apifetchCheckout,
	makeCheckoutPayment as apiMakeCheckoutPayment
} from "./api";

export function fetchCheckout(username) {
	return createAjaxAction(
		{
			type: "CHECKOUT_FOR_USER_FETCH",
			payload: { username }
		},
		getState => {
			const state = getState();
			const user = getCurrentUser(state);

			const checkout = getCheckoutByUsername(state, username);

			if (checkout.isLoading) return;

			return apifetchCheckout(user);
		}
	);
}

export function makeCheckoutPayment({ username, items, bankAccount }) {
	const type = "CHECKOUT_MAKE_PAYMENT";
	const payload = { username };
	const actions = asyncifyAction({
		type,
		payload
	});

	return async function(dispatch, getState) {
		const state = getState();
		const auth = getCurrentUser(state);
		const checkout = getCheckoutByUsername(state, username);
		if (checkout.isMakingPayment || checkout.isPaymentMade) return;

		dispatch(actions.request);

		let response = null;
		try {
			response = await apiMakeCheckoutPayment({ items, bankAccount, auth });
		} catch (err) {
			if (err.message == "Checkout items have changed.") {
				dispatch({
					type: `${type}_ITEMS_CHANGED`,
					payload: err,
					meta: payload // the original payload
				});

				dispatch(fetchCheckout(username));
			} else {
				dispatch(actions.failed(err));
			}
		}

		if (response) {
			dispatch(actions.completed(response));
		}
	};
}
