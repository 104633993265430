import React from "react";

import classNames from "classnames";
import { withStyles, LinearProgress } from "@material-ui/core";

const styles = {
	linearContainer: {
		margin: "auto",
		width: "30%",
		minWidth: "15em"
	},
	processProgress: {
		left: 0,
		right: 0
	},
	hidden: {
		opacity: 0
	}
};

const Progress = props => {
	const { progress, classes } = props;

	const linearClassNames = classNames({
		[classes.processProgress]: true,
		[classes.hidden]: !progress
	});

	const linearValue = progress
		? (100 * progress.completed) / progress.total
		: 0;

	// Determine the percent for one increment beyond the current progress
	// Determine the percent for halfway between current and complete
	// Choose the lesser of these two
	const nextIncrement = progress
		? (progress.completed + 1) / progress.total
		: 0;
	const halfwayToCompletion = progress
		? (progress.completed + progress.total) / progress.total / 2
		: 0;

	const linearBuffer = 100 * Math.min(nextIncrement, halfwayToCompletion);

	return (
		<div className={classes.linearContainer}>
			<LinearProgress
				variant="buffer"
				color="primary"
				value={linearValue}
				valueBuffer={linearBuffer}
				className={linearClassNames}
			/>
		</div>
	);
};

export default withStyles(styles)(Progress);
