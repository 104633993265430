import React, { Component } from "react";
import Root from "./root";

export const W9_INPUT_PREFIX = "isAgreedW9_";

const initState = ({ checkout = {} }) => {
	const result = {
		selectedBankAccount: null,
		selectedBankAccountName: null,
		isAgreedToTerms: false,
		isCheckoutChangeAcknowledged: false
	};
	const w9TAs = checkout.w9TaxAuthorities;
	w9TAs && w9TAs.length
		? w9TAs.forEach(({ prefix }) => {
				result[`${W9_INPUT_PREFIX}${prefix}`] = false;
		  })
		: [];

	return result;
};

export default class CheckoutState extends Component {
	state = initState(this.props);

	handleAgreeW9Change = ({ target: { name, checked } }) => {
		this.setState({
			[name]: checked
		});
	};

	handleBankAccountChange = ({ target }) => {
		this.setState({
			selectedBankAccount: target.value,
			selectedBankAccountName: target[target.selectedIndex].innerText
		});
	};

	handleAgreeToTermsChange = ({ target: { checked } }) => {
		this.setState({
			isAgreedToTerms: checked
		});
	};

	handleCheckoutChangeAcknowledgedChange = ({ target: { checked } }) => {
		this.setState({
			isCheckoutChangeAcknowledged: checked
		});
	};

	handleMakePayment = () => {
		const items = this.props.checkout.items
			.filter(({ isPastPaymentWindow }) => !isPastPaymentWindow)
			.map(({ id, amountUnpaid, typeCode, taxAuthorityPrefix }) => {
				return {
					id,
					amountUnpaid,
					taxAuthorityPrefix,
					type: typeCode == "DEP" ? "Deposit" : "Purchase"
				};
			});

		this.props.makeCheckoutPayment({
			bankAccount: this.state.selectedBankAccount,
			items,
			username: this.props.username
		});
	};

	render() {
		return (
			<Root
				onBankAccountChange={this.handleBankAccountChange}
				onAgreeToTermsChange={this.handleAgreeToTermsChange}
				onCheckoutChangeAcknowledgedChange={
					this.handleCheckoutChangeAcknowledgedChange
				}
				onMakePayment={this.handleMakePayment}
				onAgreeW9Change={this.handleAgreeW9Change}
				{...this.props}
				{...this.state}
			/>
		);
	}
}
