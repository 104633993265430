import React from "react";
import { connect } from "fetch-helpers";
import { connectCurrentUser } from "@civicsource/users";

import useChannelListen from "../../channel/listen";
import connectAuction from "../connect";

import { get, union } from "lodash";
import { createSelector } from "reselect";

const massageData = createSelector(
	props => props,
	({ user: _user, auctionInfo, ...props }) => {
		let user = null;
		if (_user || get(auctionInfo, "data.user")) {
			user = {
				..._user,
				...get(auctionInfo, "data.user")
			};
		}

		const { data, ...loadStatus } = auctionInfo || {};

		return {
			...props,
			...data,
			...loadStatus,
			routerLocation: props.location,
			user
		};
	}
);

export default ComponentToWrap => {
	const DataMassager = props => {
		useChannelListen(get(props, "auctionInfo.data.lotNumber"));

		return <ComponentToWrap {...massageData(props)} />;
	};

	const LineItems = connect(({ auctionInfo }) =>
		auctionInfo && auctionInfo.data
			? {
					lineItems: {
						url: `/api/auctions/${auctionInfo.data.id}/lineitems`,
						keys: ["auctionInfo"]
					}
			  }
			: null
	)(DataMassager);

	return connectAuction(
		connectCurrentUser(
			connect(({ taxAuthority: { code }, lotNumber, user }) => {
				return {
					auctionInfo: lotNumber
						? {
								url: `/api/auctions/?taxAuthority=${code}&lotNumber=${lotNumber}`,
								bearerToken: user ? user.token : null,

								// eslint-disable-next-line no-unused-vars
								onData: ({ taxAuthority, ...auction }) => {
									// discard the taxAuthority and use the one from statically built page
									return {
										...auction,
										taxAuthorityDisplayName: taxAuthority.name,
										status: getStatus(auction.status, auction.hasDeposit),
										isMoneyAuction: auction.biddingType === "Money",
										biddingFormatDescription:
											auction.biddingType === "Money"
												? "High dollar wins"
												: "Low percentage wins",
										market:
											auction.auctionType == "SecondaryMarket"
												? "Secondary"
												: "Primary",
										...getAuctionTypes(auction.auctionType)
									};
								},
								keys: ["lotNumber", "user"]
						  }
						: null
				};
			})(LineItems)
		)
	);
};

export const getAuctionTypes = type => ({
	isAdjudicationSale: type === "Adjudication",
	isTaxSale: type === "TaxSale",
	isSecondaryMarket: type === "LienSale"
});

const preResearch = ["Candidate", "Public"];
const preSale = ["Researching", "ResearchComplete"];
const duringSale = ["ForSale"];
const postSale = ["Sold", "NotSold", "Cancelled"];
const statuses = union(preResearch, preSale, duringSale, postSale);

const getStatus = (status, hasDeposit) => {
	const result = {};
	result.name = status;

	statuses.forEach(name => {
		result[`is${name}`] = status === name;
	});

	result.isPreResearch = preResearch.includes(status) && !hasDeposit;

	result.isPreSale =
		preSale.includes(status) || (status === "Public" && hasDeposit);

	result.isDuringSale = duringSale.includes(status);

	result.isPostSale = postSale.includes(status);
	return result;
};
