import React from "react";
import { Address } from "@civicsource/ui";
import { Table } from "react-bootstrap";

const hasAddress = ({ address1 }) =>
	!!(address1 && address1.trim && address1.trim());

const ProcessingInstructions = ({
	processingInstructions,
	email,
	fax,
	mailingAddress,
	physicalAddress
}) => {
	return (
		<div>
			<Table>
				<tbody>
					<tr className="active">
						<th>Instructions</th>
						<td>{processingInstructions}</td>
					</tr>
					{email && (
						<tr>
							<th scope="row">Email</th>
							<td>
								<a href={`mailto:${email}?subject=W9%20Form`}>{email}</a>
							</td>
						</tr>
					)}
					{fax && (
						<tr>
							<th scope="row">Fax</th>
							<td>{fax}</td>
						</tr>
					)}
					{hasAddress(physicalAddress) && (
						<tr>
							<th>Physical Address</th>
							<td>
								<Address address={physicalAddress} />
							</td>
						</tr>
					)}
					{hasAddress(mailingAddress) && (
						<tr>
							<th>Mailing Address</th>
							<td>
								<Address address={mailingAddress} />
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default ProcessingInstructions;
