import React from "react";
import { Checkbox } from "react-bootstrap";

const TermsAgreer = ({
	isAgreedToTerms,
	onAgreeToTermsChange,
	isMakingPayment
}) => {
	return (
		<Checkbox
			disabled={isMakingPayment}
			checked={isAgreedToTerms}
			onChange={onAgreeToTermsChange}
		>
			{" "}
			<strong>I agree</strong> to the{" "}
			<a href="/content/termsofuse/" rel="noopener noreferrer" target="_blank">
				Terms of Use
			</a>
			.
		</Checkbox>
	);
};

export default TermsAgreer;
