import React from "react";
import Root from "./root";

import Provider from "../state/provider";

import { deregisterNotification } from "@civicsource/ui";
// import configureLogging from "../../logging";

// configureLogging(store.getState());

// see CS-15311
// There is a bug sometimes when fetching TA info that we cannot reproduce reliably.
// It has something to do with caching the result of the initial OPTIONS request when fetching TA info.
// It complains about a missing CORs header and aborts the request.
// For now, disabling the error notification on the checkout page when this happens.
// It causes no loss of functionality on the page. It only shows less info about the TA for each checkout item.
deregisterNotification("FETCH_TAX_AUTHORITY_FAILED");

const CheckoutWithProvider = ({ supportPhone }) => (
	<Provider element={<Root supportPhone={supportPhone} />} />
);

export default CheckoutWithProvider;
